<template>
    <div class="createPromotionType">
        <el-card shadow="never" style="margin-top: 8px">
            <span style="color: red"> 请尽量不要投放可能冲突的活动类型, 否则可能不能正确计算促销优惠价格 </span>
        </el-card>
        <el-card class="box-card" style="margin-top: 10px; display: flex" shadow="never">
            <div style="display: flex; padding: 16px 0">
                <el-card class="type clr-purpose" @click.native="netStep(4)" shadow="never">
                    <div class="title">单品件数折扣</div>
                    <div>针对选择的范围，买满一定件数进行折扣；例如全场满10个95折，某个商品5件9折，10件8折等</div>
                </el-card>
                <el-card class="type clr-orange" @click.native="netStep(5)">
                    <div class="title">任选一口价</div>
                    <div>
                        针对选择的范围，买满指定数量商品，打包一起卖指定价格，例如：某3种商品,任选2件10元,任选4件15元
                    </div>
                </el-card>
                <el-card class="type clr-pink" @click.native="netStep(6)">
                    <div class="title">单品满赠</div>
                    <div>针对单品买满一定件数送赠品；例如买3袋豆浆粉送1个水晶杯</div>
                </el-card>
                <el-card class="type clr-green" @click.native="netStep(7)">
                    <div class="title">固定一口价</div>
                    <div>使用固定套餐一口价进行促销；例如面包粉2件加上蛋糕粉3件原价购买90元，固定套餐价格75元</div>
                </el-card>
                <el-card class="type clr-blue" @click.native="netStep(8)">
                    <div class="title">任选件数折扣</div>
                    <div>针对指定的商品，买满一定件数给予商品折扣；例如指定3种商品，任意组合，2件打9折，3件打8折</div>
                </el-card>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    name: 'CreatePromotionType',
    methods: {
        netStep(type) {
            if (type === 4) {
                this.$router.replace({
                    name: 'menu.promotion.npromotion.create.specialOffer',
                });
            } else if (type === 5) {
                this.$router.replace({
                    name: 'menu.promotion.npromotion.create.buyoutPrice',
                });
            } else if (type === 6) {
                this.$router.replace({
                    name: 'menu.promotion.npromotion.create.giveaway',
                });
            } else if (type === 7) {
                this.$router.replace({
                    name: 'menu.promotion.npromotion.create.fixBuyOut',
                });
            } else if (type === 8) {
                this.$router.replace({
                    name: 'menu.promotion.npromotion.create.fixDiscount',
                });
            }

            //a
            this.$store.commit('delTabRoute', 'menu.promotion.npromotion.create.type');
        },
    },
};
</script>

<style scoped>
.createPromotionType {
    background-color: #f5f7ff;
}

.createPromotionType .type {
    width: 272px;
    height: 150px;
    margin: auto 20px 10px 20px;
    cursor: pointer;
    padding-top: 10px;
    box-shadow: 0 4px 10px 3px rgba(0, 0, 0, 0.1);
}

.createPromotionType .type .title {
    font-weight: bold;
    margin-bottom: 10px;
}
</style>
